import React from "react"
import { Link } from "gatsby"
import Layout from "../components/pageSections/layout"
import SEO from "../components/pageSections/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Nothing to see here.</p>
    <Link to="/">Head home</Link>
  </Layout>
)

export default NotFoundPage